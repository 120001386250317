import { Api } from '@/core/api.js'
import store from '@/store'
import i18n from '@/i18n'
import router from '@/router'
import localforage from 'localforage'
import Vue from 'vue'

const api = new Api()

const db_issues = localforage.createInstance({ name: 'alexdb', storeName: 'issues' })
const db_issues_templates = localforage.createInstance({ name: 'alexdb', storeName: 'issues_templates' })
const db_tasks = localforage.createInstance({ name: 'alexdb', storeName: 'tasks' })
const db_templates = localforage.createInstance({ name: 'alexdb', storeName: 'templates' })
const db_timers = localforage.createInstance({ name: 'alexdb', storeName: 'timers' })
const db_checks_to_sync = localforage.createInstance({ name: 'alexdb', storeName: 'checks_to_sync' })
const db_login = localforage.createInstance({ name: 'alexdb', storeName: 'login' })
const db_updates = localforage.createInstance({ name: 'alexdb', storeName: 'updates' })
const db_employees = localforage.createInstance({ name: 'alexdb', storeName: 'employee' })
const db_roles = localforage.createInstance({ name: 'alexdb', storeName: 'roles' })
const db_categories = localforage.createInstance({ name: 'alexdb', storeName: 'product_categories' })
const db_products = localforage.createInstance({ name: 'alexdb', storeName: 'products' })
const db_collections = localforage.createInstance({ name: 'alexdb', storeName: 'product_collections' })
const db_printlogs = localforage.createInstance({ name: 'alexdb', storeName: 'product_printlogs' })
const db_error_logs = localforage.createInstance({ name: 'alexdb', storeName: 'product_print_error_logs' })
const db_library_categories = localforage.createInstance({ name: 'alexdb', storeName: 'library_categories' })
const db_sections = localforage.createInstance({ name: 'alexdb', storeName: 'library_sections' })
const db_files = localforage.createInstance({ name: 'alexdb', storeName: 'library_files' })
const db_highlight_files = localforage.createInstance({ name: 'alexdb', storeName: 'library_highlight_files' })
const db_logbook = localforage.createInstance({ name: 'alexdb', storeName: 'logbook' })
const db_legal_conditions = localforage.createInstance({ name: 'alexdb', storeName: 'legal_conditions' })
const dbEmployeeLoginLogs = localforage.createInstance({ name: 'alexdb', storeName: 'employee_login_logs' })
const db_printlogs_android = localforage.createInstance({ name: 'alexdb', storeName: 'product_printlogs_android' })
const db_languages = localforage.createInstance({ name: 'alexdb', storeName: 'languages' })
const db_locations = localforage.createInstance({ name: 'alexdb', storeName: 'locations' })
const db_tasks_queue = localforage.createInstance({ name: 'alexdb', storeName: 'tasks_queue' })
const db_registers_queue = localforage.createInstance({ name: 'alexdb', storeName: 'registers_queue' })
const registers_items = localforage.createInstance({ name: 'alexdb', storeName: 'registers_items' })
const audits_items = localforage.createInstance({ name: 'alexdb', storeName: 'audits_items' })
const db_audits_queue = localforage.createInstance({ name: 'alexdb', storeName: 'audits_queue' })
const issues_items = localforage.createInstance({ name: 'alexdb', storeName: 'issues_items' })
const db_issues_queue = localforage.createInstance({ name: 'alexdb', storeName: 'issues_queue' })
const db_registers = localforage.createInstance({ name: 'alexdb', storeName: 'registers' })
const db_screenlock = localforage.createInstance({ name: 'alexdb', storeName: 'screenlock' })
//const db_locations_auditor = localforage.createInstance({ name: 'alexdb', storeName: 'db_locations_auditor' })

// initial state
const state = {
    location: {},
    account: -1,
    config: {},
    printer: {},
    hasLogin: false,
    conditionsVersion: -1,
    app_android: '',
    app_icon: '',
    token: '',
    roles: {},
    loadLocalForage: false,
    releases: {},
    allReleases: {},
    blackListData: {
        warning: false,
        blocked: false
    },
    screenlock: {
        checklist_id: false,
        message: ''
    },
    wrong_ip: false,
    trial_end_date: null,
    showTrialEndScreen: false,
    trial_days_left: 0,
    trial_message: null,
    removeRolesLevel: false,
    auditor: false,
    isLoadedLocation: false,
    auditorLocalSeleceted: false,
    kitchenModeFeatureFlag: null // KITCHEN MODE FEATURE FLAG
}

// getters
const getters = {
    getHasLogin: (state, getters, rootState) => {
        return state.hasLogin
    },
    getConfig: (state, getters, rootState) => {
        return state.config
    },
    getConditionsVersion: async (state, getters, rootState) => {
        return state.conditionsVersion != -1 ? state.conditionsVersion : await db_login.getItem('conditions_version')
    },
    getToken: (state, getters, rootState) => {
        return state.token
    },
    getAppAndroid: (state, getters, rootState) => {
        return state.app_android
    },
    getRoles: (state, getters, rootState) => {
        return state.roles
    },
    getLocation: (state, getters, rootState) => {
        return state.location
    },
    getAccount: (state, getters, rootState) => {
        return state.account
    },
    getPrinter: (state, getters, rootState) => {
        return state.printer
    },
    getConfig: (state, getters, rootState) => {
        return state.config
    },
    isLoad: (state, getters, rootState) => {
        return state.loadLocalForage
    },
    getCPUrl: (state, getters, rootState) => {
        return process.env.VUE_APP_CP_URL
    },
    getAPIurl: (state, getters, rootState) => {
        return process.env.VUE_APP_API_URL
    },
    getReleases: (state, getters, rootState) => (id) => {
        if (id) {
            return state.releases[id]
        } else {
            const items = state.releases
            const sortedItems = Object.values(items).sort((a, b) => {
                const date1 = moment(a.created_date * 1000)
                const date2 = moment(b.created_date * 1000)
                if (date1 > date2) return 1
                if (date1 < date2) return -1
                return 0
            })
            return sortedItems
        }
    },
    getAllReleases: (state, getters, rootState) => (id) => {
        if (id) {
            return state.allReleases[id]
        } else {
            const items = state.allReleases
            if (items) {
                const sortedItems = Object.values(items).sort((a, b) => {
                    const date1 = moment(a.created_date * 1000)
                    const date2 = moment(b.created_date * 1000)
                    if (date1 > date2) return 1
                    if (date1 < date2) return -1
                    return 0
                })
                return sortedItems
            }

            return []
        }
    },
    getBlackListData: (state, getters, rootState) => {
        return state.blackListData
    },
    getScreenlockData: (state, getters, rootState) => {
        return state.screenlock
    },
    getWrongIp: (state, getters, rootState) => {
        return state.wrong_ip
    },
    getTrialEndDate: (state, getters, rootState) => {
        return state.trial_end_date
    },
    getShowTrialEndScreen: (state, getters, rootState) => {
        return state.showTrialEndScreen
    },
    getTrialDaysLeft: (state, getters, rootState) => {
        return state.trial_days_left
    },
    getRemoveRolesLevel: (state, getters, rootState) => {
        return state.removeRolesLevel
    },
    getTrialMessage: (state, getters, rootState) => {
        return state.trial_message
    },
    getIsLoadedLocation: (state, getters, rootState) => {
        const location = state.location
        if (location && Object.keys(location).length > 0) {
            return true
        }

        return state.isLoadedLocation
    },
    getAuditorLocalSeleceted: (state, getters, rootState) => {
        return state.auditorLocalSeleceted
    },
    getKitchenModeFeatureFlag: (state) => {
        return state.kitchenModeFeatureFlag
    }
}

// actions
const actions = {
    async doLogin(context, params) {
        // // var overlay = this._vm.$overlay
        // overlay.loading()
        context.commit('logout')

        const appVersion = process.env.VUE_APP_VERSION
        const platform = process.env.VUE_APP_IS_APP == 'TRUE' ? 1 : 2
        const device =
            process.env.VUE_APP_IS_APP == 'TRUE'
                ? {
                      manufacture: window.device.manufacture,
                      model: window.device.model,
                      plaftorm: window.device.platform,
                      version: window.device.version
                  }
                : {}

        return api
            .login('login', {
                username: params.username,
                password: params.password,
                version: appVersion,
                platform: platform,
                device: JSON.stringify(device)
            })
            .then(async function (response) {
                // store.dispatch( 'checkChangesPrint', response.data.printer );

                // clear offline database
                await cleanLocalForage()

                if (response.status) {
                    await db_locations.setItem(params.username.toLowerCase(), {
                        account_name: response.data.account_name,
                        account_logo: response.data.avatar,
                        location_name: response.data.name,
                        password: window.btoa(params.password),
                        city: response.data.city,
                        state: response.data.state,
                        address: response.data.address,
                        cp: response.data.cp,
                        lang: response.data.lang
                    })

                    // KITCHEN MODE FEATURE FLAG
                    if (response.data && response.data.config && response.data.config.kitchen_mode) {
                        context.commit('setKitchenModeFeatureFlag', response.data.config.kitchen_mode)
                    }

                    if (typeof response.data.current_time !== 'undefined' && response.data.current_time) {
                        const currentTime = moment(moment(response.data.current_time).format('YYYY-MM-DD HH:mm:ss'))
                        const now = moment(moment().format('YYYY-MM-DD HH:mm:ss'))
                        const difference = Math.round(currentTime.diff(now, 'minutes') / 60) * 60
                        context.commit('setServerTimeDifference', difference, { root: true })
                    }

                    context.commit('setTimeZoneName', response.data.timezone_name, { root: true })

                    context.commit('setVersionCurrent', response.data.version_current, { root: true })
                    context.commit('setVersionMin', response.data.version_min, { root: true })
                    context.commit('setVersionRecommended', response.data.version_recommended, { root: true })

                    //   store.dispatch("checkChangesPrint", response.data.printer);
                    context.dispatch('loadData', response.data)
                    context.dispatch('loadAppIcon', { path: response.data.app_icon, lastUpdate: response.data.app_icon_last_update * 1000 }, { root: true })
                    context.dispatch('loadExternalTools', response.data.menu_items, { root: true })
                    context.dispatch('pusher/init', {}, { root: true })
                    var printer = Object.assign(response.data.printer)
                    log('SETTING PRINTER', printer)
                    // context.commit('printer/setPrinterData', printer, { root: true })
                    context.dispatch('printer/savePrinterData', printer, { root: true })

                    if (response.data.config && response.data.config.home) {
                        context.commit('setCustomHome', response.data.config.home, { root: true })
                    } else {
                        context.commit('setCustomHome', false, { root: true })
                    }

                    // free labelling
                    const freeLabelling = response.data.config && typeof response.data.config.freeLabelling !== 'undefined' && response.data.config.freeLabelling ? 1 : 0
                    context.commit('setFreeLabelling', freeLabelling, { root: true })

                    const pastLabelling = response.data.config && typeof response.data.config.pastLabelling !== 'undefined' && response.data.config.pastLabelling ? 1 : 0
                    context.commit('setPastLabelling', pastLabelling, { root: true })

                    const sensorLimitation = response.data.config && typeof response.data.config.sensorLimitation !== 'undefined' && response.data.config.sensorLimitation ? response.data.config.sensorLimitation : 0
                    context.commit('setSensorLimitation', sensorLimitation, { root: true })

                    const relatedLabels = response.data.config && typeof response.data.config.relatedLabels !== 'undefined' && response.data.config.relatedLabels ? response.data.config.relatedLabels : 0
                    context.commit('setRelatedLabels', relatedLabels, { root: true })

                    const dateLimitation = response.data.config && typeof response.data.config.dateLimitation !== 'undefined' && response.data.config.dateLimitation ? response.data.config.dateLimitation : 0
                    context.commit('setDateLimitation', dateLimitation, { root: true })

                    const canCreateUsers = response.data.config && typeof response.data.config.canCreateUsers !== 'undefined' ? response.data.config.canCreateUsers : 1
                    context.commit('setCanCreateUsers', canCreateUsers, { root: true })

                    const maxLabelsPrint = response.data.config && typeof response.data.config.maxLabelsPrint !== 'undefined' && response.data.config.maxLabelsPrint ? response.data.config.maxLabelsPrint : 100
                    context.commit('setMaxLabelsPrint', maxLabelsPrint, { root: true })

                    const callLoginUsers = response.data.config && typeof response.data.config.callLoginUsers !== 'undefined' ? response.data.config.callLoginUsers : 0
                    context.commit('setCallLoginUsers', callLoginUsers, { root: true })

                    const timeout = response.data.config && typeof response.data.config.timeout !== 'undefined' && response.data.config.timeout ? parseInt(response.data.config.timeout) * 60 : idle_time
                    context.commit('setTimeout', timeout, { root: true })

                    const license_end_date = response.data && typeof response.data.license_end_date !== 'undefined' && response.data.license_end_date ? response.data.license_end_date : false

                    context.commit('setLicenseEndDate', license_end_date, { root: true })

                    const note_img = response.data.config && typeof response.data.config.note_img !== 'undefined' && response.data.config.note_img ? response.data.config.note_img : 'all'
                    context.commit('setNoteImg', note_img, { root: true })

                    const screenlockFlag = response.data.config && typeof response.data.config.screenlock !== 'undefined' ? response.data.config.screenlock : 0
                    context.commit('setScreenlockFlag', screenlockFlag, { root: true })

                    const offline = response.data.offline ? response.data.offline : 0
                    context.commit('setOffline', offline, { root: true })

                    context.commit(
                        'loginUser/setLocation',
                        {
                            account_name: response.data.account_name,
                            id: response.data.id,
                            name: response.data.name,
                            avatar: response.data.avatar,
                            identification: response.data.identification,
                            username: response.data.username,
                            timezone: response.data.timezone,
                            email: response.data.email,
                            token: response.data.token,
                            city: response.data.city,
                            state: response.data.state,
                            address: response.data.address,
                            cp: response.data.cp
                        },
                        { root: true }
                    )

                    context.commit('loginUser/setAccount', response.data.account_id, { root: true })

                    context.commit('setReleases', response.data.releases)

                    context.commit('login', response)

                    context.dispatch('loadTimeZonesAndLanguages', {})

                    context.commit('setTrialEndDate', response.data.trial_end_date)
                    context.dispatch('calculateTrialDaysLeft', {})
                    context.commit('setTrialMessage', response.data.trial_message)

                    context.commit('setWrongIp', false)
                    context.commit('setisLoaded', true)
                    context.commit('setIsLoadedLocation', true)

                    //   context.commit('employee/setRoles',response.data.roles,{root: true})
                    if (context.rootGetters.getIsAndroid && FirebasePlugin) {
                        log('SETTING USER INFO Analytics & Crashlytics')
                        FirebasePlugin.setUserId(response.data.id)
                        FirebasePlugin.setUserProperty('account', response.data.account_id)
                        FirebasePlugin.setUserProperty('location_name', response.data.location_name)
                        FirebasePlugin.setCrashlyticsUserId(response.data.id)
                    }
                } else {
                    if (response.blackListWarning) {
                        context.commit('setBlacklistData', { warning: true, blocked: false })
                    }
                    if (response.blackListBlocked) {
                        context.commit('setBlacklistData', { warning: false, blocked: true })
                    }
                    if (response.wrong_ip) {
                        context.commit('setWrongIp', true)
                    } else {
                        context.commit('setWrongIp', false)
                    }
                    return false
                }
            })
            .then(function () {
                if (context.state.hasLogin) {
                    return true
                } else {
                    return false
                }
            })
    },

    async doLoginAuditor(context, params) {
        context.commit('logout')

        const appVersion = process.env.VUE_APP_VERSION
        const platform = process.env.VUE_APP_IS_APP == 'TRUE' ? 1 : 2
        const device =
            process.env.VUE_APP_IS_APP == 'TRUE'
                ? {
                      manufacture: window.device.manufacture,
                      model: window.device.model,
                      plaftorm: window.device.platform,
                      version: window.device.version
                  }
                : {}
        log('DO LOGIN AUDITOR', params, appVersion, platform, device)
        return api
            .login('login/auditor', {
                email: params.email,
                password: params.password,
                version: appVersion,
                platform: platform,
                device: JSON.stringify(device)
            })
            .then(async function (response) {
                await cleanLocalForage()
                if (response.status) {
                    log('RESPONSE LOGIN AUDITOR', response)

                    //Locations
                    if (response.data.locations) {
                        const locations = []
                        for (let location of response.data.locations) {
                            locations.push({
                                account_name: location.account_name,
                                account_logo: location.account_logo,
                                location_name: location.location_name,
                                city: location.city,
                                state: location.state,
                                address: location.address,
                                cp: location.cp,
                                lang: location.lang
                            })
                        }

                        context.commit('loginUser/setLocations', response.data.locations, { root: true })
                    }

                    context.commit('login/setToken', response.token, { root: true })
                    const auditor = { ...response.data }
                    delete auditor.locations
                    context.commit('loginUser/setAuditor', auditor, { root: true })
                    context.commit('setAuditorLogin', auditor, { root: true })
                    const timeout = response.data.config && typeof response.data.config.timeout !== 'undefined' && response.data.config.timeout ? parseInt(response.data.config.timeout) * 60 : idle_time
                    context.commit('setTimeout', timeout, { root: true })

                    if (response.data.config) {
                        const config = JSON.parse(JSON.stringify(response.data.config))
                        context.commit('setConfig', config)
                        if (config.home) {
                            context.commit('setCustomHome', config.home, { root: true })
                        }
                    } else {
                        context.commit('setCustomHome', false, { root: true })
                    }

                    return true
                } else {
                    return false
                }
            })
            .catch((err) => {
                logError(err)
                return false
            })
    },

    async doLoadLocalForage() {
        log('doLoadLocalForage')
        await initLocalForage()
    },

    async initLocation(context) {
        // this._vm.$overlay.loading()

        await initLocalForage()
        let last_action = context.rootGetters.getLastEmployeeAction
        let employee_id = store.getters['loginUser/getLocalEmployee']
        log('LAST ACTION EMPLOYEE DETECTED', last_action, moment().valueOf() - context.rootGetters.getTimeout * 1000)

        if (last_action && last_action.time < moment().valueOf() - context.rootGetters.getTimeout * 1000 && employee_id) {
            context
                .dispatch(
                    'loginUser/doLogout',
                    {
                        employee: employee_id
                    },
                    { root: true }
                )
                .then(function (response) {
                    // router.push(mixin.redirectPath('/home')).catch(err => {
                    //     if (err.name == 'NavigationDuplicated') {
                    //         router.go(router.history.current)
                    //     }
                    // })
                })
        }

        if (fnCheckConnection()) {
            log('INIT API INIT')
            return api
                .getSession({ mq: store.getters['getMediaQuery'], auditor: store.getters['loginUser/getAuditor'] })
                .then(async function (response) {
                    log('LOGIN DATA')
                    log(response)

                    if (response && (!response.status || response.status == 'false')) {
                        console.error(response)
                        if (response.wrong_ip) {
                            context.commit('setWrongIp', true)
                        } else {
                            context.commit('setWrongIp', false)
                            if (process.env.VUE_APP_URL_TYPE == 'HASH' && process.env.VUE_APP_IS_APP !== 'TRUE') {
                                window.location.href = process.env.VUE_APP_IS_APP === 'TRUE' ? cordova.file.applicationDirectory + 'www/index.html#/login' : window.window.location.origin + (process.env.VUE_APP_URL_TYPE == 'HASH' ? '/#' : '') + '/login'
                            } else {
                                router.push({ name: 'Login' })
                                return true
                            }
                        }
                    }

                    if (response && response.data) {
                        //Saltar esto si es auditor
                        if (response.data.passwd && response.data.username && !response.data.auditor) {
                            //Obtener datos de la base de datos local con la key del usuario
                            let location_info = await db_locations.getItem(response.data.username.toLowerCase())
                            if (location_info.password != window.btoa(response.data.passwd)) {
                                //Logout
                                context.dispatch('logout').then(function () {
                                    router.push({ name: 'Login' }).then(() => {
                                        window.location.reload()
                                    })
                                })
                            }
                        }

                        if (response.data.config.api) {
                            localStorage.setItem('api', response.data.config.api)
                        } else {
                            if (localStorage.getItem('api')) {
                                localStorage.removeItem('api')
                            }
                        }

                        context.commit('setWrongIp', false)
                        log('FIN API INIT', response.data.username.toLowerCase())
                        let location_info = await db_locations.getItem(response.data.username.toLowerCase())
                        log('ENTRANDO INIT', response.data.username, {
                            account_name: response.data.account_name,
                            account_logo: response.data.avatar,
                            location_name: response.data.name,
                            password: location_info ? location_info.password : '',
                            city: response.data.city,
                            state: response.data.state,
                            address: response.data.address,
                            cp: response.data.cp,
                            lang: response.data.lang
                        })
                        await db_locations.setItem(response.data.username.toLowerCase(), {
                            account_name: response.data.account_name,
                            account_logo: response.data.avatar,
                            location_name: response.data.name,
                            password: location_info ? location_info.password : '',
                            city: response.data.city,
                            state: response.data.state,
                            address: response.data.address,
                            cp: response.data.cp,
                            lang: response.data.lang
                        })

                        if (typeof response.data.current_time !== 'undefined' && response.data.current_time) {
                            const currentTime = moment(moment(response.data.current_time).format('YYYY-MM-DD HH:mm'))
                            const now = moment(moment().format('YYYY-MM-DD HH:mm'))
                            const difference = Math.round(currentTime.diff(now, 'minutes') / 60) * 60
                            context.commit('setServerTimeDifference', difference, { root: true })
                        }

                        // KITCHEN MODE FEATURE FLAG
                        if (response.data && response.data.config && response.data.config.kitchen_mode) {
                            context.commit('setKitchenModeFeatureFlag', response.data.config.kitchen_mode)
                        }

                        context.commit('setVersionCurrent', response.data.version_current, { root: true })
                        context.commit('setVersionMin', response.data.version_min, { root: true })
                        context.commit('setVersionRecommended', response.data.version_recommended, { root: true })
                        context.commit('setChatActive', response.data.chat, { root: true })

                        context.commit('setTimeZoneName', response.data.timezone_name, { root: true })

                        context.commit('setTrialEndDate', response.data.trial_end_date)
                        context.dispatch('calculateTrialDaysLeft', {})
                        context.commit('setTrialMessage', response.data.trial_message)

                        context.commit('setisLoaded', true)
                        context.dispatch('loadData', response.data)
                        context.dispatch('loadAppIcon', { path: response.data.app_icon, lastUpdate: response.data.app_icon_last_update * 1000 }, { root: true })
                        context.dispatch('loadExternalTools', response.data.menu_items, { root: true })
                        context.dispatch('pusher/init', {}, { root: true })
                        //   context.commit('employee/setRoles',response.data.roles,{root: true})
                        var printer = Object.assign({}, response.data.printer)
                        // context.commit('printer/setPrinterData', printer, { root: true })
                        context.dispatch('printer/savePrinterData', printer, { root: true }).then(() => {
                            log('FINISH SET PRINTER INFO')
                        })

                        if (response.data.config && response.data.config.home) {
                            context.commit('setCustomHome', response.data.config.home, { root: true })
                        } else {
                            context.commit('setCustomHome', false, { root: true })
                        }

                        if (response.data.config && response.data.config.screenlock && context.rootGetters['loginUser/getLocalEmployee']) {
                            context.dispatch('getScreenlockTasks')
                        }

                        // update free labelling
                        if (response.data.config && response.data.config.freeLabelling) {
                            context.commit('setFreeLabelling', response.data.config.freeLabelling, { root: true })
                        } else {
                            context.commit('setFreeLabelling', 0, { root: true })
                        }

                        if (response.data.config && response.data.config.pastLabelling) {
                            context.commit('setPastLabelling', response.data.config.pastLabelling, { root: true })
                        } else {
                            context.commit('setPastLabelling', 0, { root: true })
                        }

                        const sensorLimitation = response.data.config && typeof response.data.config.sensorLimitation !== 'undefined' && response.data.config.sensorLimitation ? response.data.config.sensorLimitation : 0
                        context.commit('setSensorLimitation', sensorLimitation, { root: true })

                        const dateLimitation = response.data.config && typeof response.data.config.dateLimitation !== 'undefined' && response.data.config.dateLimitation ? response.data.config.dateLimitation : 0
                        context.commit('setDateLimitation', dateLimitation, { root: true })

                        const relatedLabels = response.data.config && typeof response.data.config.relatedLabels !== 'undefined' && response.data.config.relatedLabels ? response.data.config.relatedLabels : 0
                        context.commit('setRelatedLabels', relatedLabels, { root: true })

                        const canCreateUsers = response.data.config && typeof response.data.config.canCreateUsers !== 'undefined' ? response.data.config.canCreateUsers : 1
                        context.commit('setCanCreateUsers', canCreateUsers, { root: true })

                        const maxLabelsPrint = response.data.config && typeof response.data.config.maxLabelsPrint !== 'undefined' && response.data.config.maxLabelsPrint ? response.data.config.maxLabelsPrint : 100
                        context.commit('setMaxLabelsPrint', maxLabelsPrint, { root: true })

                        const callLoginUsers = response.data.config && typeof response.data.config.callLoginUsers !== 'undefined' ? response.data.config.callLoginUsers : 0
                        context.commit('setCallLoginUsers', callLoginUsers, { root: true })

                        const timeout = response.data.config && typeof response.data.config.timeout !== 'undefined' && response.data.config.timeout ? parseInt(response.data.config.timeout) * 60 : idle_time
                        context.commit('setTimeout', timeout, { root: true })

                        const license_end_date = response.data && typeof response.data.license_end_date !== 'undefined' && response.data.license_end_date ? response.data.license_end_date : false
                        context.commit('setLicenseEndDate', license_end_date, { root: true })

                        const note_img = response.data.config && typeof response.data.config.note_img !== 'undefined' && response.data.config.note_img ? response.data.config.note_img : 'all'
                        context.commit('setNoteImg', note_img, { root: true })

                        const screenlockFlag = response.data.config && typeof response.data.config.screenlock !== 'undefined' ? response.data.config.screenlock : 0
                        context.commit('setScreenlockFlag', screenlockFlag, { root: true })

                        const offline = response.data.offline ? response.data.offline : 0
                        context.commit('setOffline', offline, { root: true })

                        context.commit(
                            'loginUser/setLocation',
                            {
                                id: response.data.id,
                                name: response.data.name,
                                account_name: response.data.account_name,
                                avatar: response.data.avatar,
                                identification: response.data.identification,
                                username: response.data.username,
                                timezone: response.data.timezone,
                                email: response.data.email,
                                token: response.data.token,
                                city: response.data.city,
                                state: response.data.state,
                                address: response.data.address,
                                cp: response.data.cp
                            },
                            { root: true }
                        )
                        context.commit('conditionsVersion', response.data.conditions_version)

                        context.commit('setReleases', response.data.releases)

                        context.dispatch('loadTimeZonesAndLanguages', {})

                        return context.commit('login', response)
                    }

                    context.commit('setWrongIp', false)

                    return context.commit('setLogin')
                })
                .catch((err) => {
                    console.error('403', err)
                    return context.commit('setLogin')
                })
        } else {
            let location_info = await db_locations.getItem(state.location.username)
            context.commit(
                'loginUser/setLocation',
                {
                    id: state.location.id,
                    name: state.location.name,
                    account_name: location_info.account_name,
                    avatar: state.location.avatar,
                    identification: state.location.identification,
                    username: state.location.username,
                    timezone: state.location.timezone,
                    email: state.location.email,
                    token: state.location.token,
                    city: location_info.city,
                    state: location_info.state,
                    address: location_info.address,
                    cp: location_info.cp
                },
                { root: true }
            )
            let lang = location_info.lang ? location_info.lang : 'es'
            switch (lang) {
                case 'es_ES':
                    lang = 'es'
                    break
                case 'en_US':
                    lang = 'en'
                    break
                case 'pt_PT':
                    lang = 'pt'
                    break
                case 'de_DE':
                    lang = 'de'
                    break
                case 'fr_FR':
                    lang = 'fr'
                    break
            }
            store.commit('changeLanguage', lang)
            return context.commit('setLogin')
        }
    },

    async initAuditor(context) {
        await initLocalForage()

        // En initAuditor no interesa que se desloguee el usuario si no hay actividad

        if (fnCheckConnection()) {
            // Mirar si hay wrong_ip
            // Comprobar contraseñas
            return context.commit('setLogin')
        } else {
            /*  let locations = []
            let keys = await db_locations_auditor.keys()
            for (let key of keys) {
                let location = await db_locations_auditor.getItem(key)
                locations.push(location)
            }

            context.commit('loginUser/setLocations', locations, { root: true }) */
            const auditorInfo = store.getters['loginUser/getAuditor']

            let lang = auditorInfo.lang ? auditorInfo.lang : 'es'
            switch (lang) {
                case 'es_ES':
                    lang = 'es'
                    break
                case 'en_US':
                    lang = 'en'
                    break
                case 'pt_PT':
                    lang = 'pt'
                    break
                case 'de_DE':
                    lang = 'de'
                    break
                case 'fr_FR':
                    lang = 'fr'
                    break
            }
            store.commit('changeLanguage', lang)
            return context.commit('setLogin')
        }
    },

    loadData(context, data) {
        log('loadData', data)
        // Set reasons

        var lang = 'es'
        switch (data.lang) {
            case 'es_ES':
                lang = 'es'
                break
            case 'en_US':
                lang = 'en'
                break
            case 'pt_PT':
                lang = 'pt'
                break
            case 'de_DE':
                lang = 'de'
                break
            case 'fr_FR':
                lang = 'fr'
                break
        }
        store.commit('changeLanguage', lang)

        if (data.reason_postpone) {
            let rsPostpone = data.reason_postpone
            rsPostpone['0'] = {
                id: '0',
                name: i18n.t('answer.postpone.default_reason')
            }
            store.commit('setReasonPostpone', rsPostpone)
        }
        if (data.reason_justify) {
            let rsJustify = data.reason_justify
            rsJustify['0'] = {
                id: '0',
                name: i18n.t('answer.justify.default_reason')
            }
            store.commit('setReasonJustify', rsJustify)
        }

        // Set states of issues
        if (data.issue_states) {
            let stIssues = data.issue_states
            for (let issueId in stIssues) {
                stIssues[issueId].default = stIssues[issueId].type == 1 ? 1 : 0
            }
            store.commit('setIssueStates', stIssues)
        }
    },

    async logout(context, data) {
        const account = await db_login.getItem('account')
        const auditor = await db_login.getItem('auditorInfo')

        // cleanLocalForage()

        if (fnCheckConnection()) {
            return api
                .login('logout', { location_id: account, employee_id: auditor ? auditor.employee_id : null })
                .then(function (response) {
                    if (response.status) {
                        context.dispatch('loadData', response.data)
                        return context.commit('logout')
                    } else {
                        return false
                    }
                })
                .then(function () {
                    return true
                })
                .catch((err) => {
                    logError(err)
                    return context.commit('logout')
                })
        } else {
            return context.commit('logout')
        }
    },

    async logoutAuditorLocal(context) {
        context.commit('setIsLoadedLocation', false)
        await db_locations.clear()
        await db_login.removeItem('location')
        state.location = {}
    },

    async clearLoginData(context) {
        await cleanLocalForage()
        await db_locations.clear()
        //Set auditor false and isLoadedLocation false
        context.commit('setAuditorLogin', false)
        context.commit('setIsLoadedLocation', false)
        context.commit('loginUser/setAuditor', {}, { root: true })
        context.commit('logout')
    },

    async loadDashboardAccess(context, params) {
        const appVersion = process.env.VUE_APP_VERSION
        const platform = process.env.VUE_APP_IS_APP == 'TRUE' ? 1 : 2

        Vue.set(state, 'loadLocalForage', false)

        await cleanLocalForage()

        return api
            .post('initdbpreview/' + params.location + '/' + params.id, { token: params.token, version: appVersion, platform: platform })
            .then(function (response) {
                if (response && !response.status) {
                    router.push('/login')
                } else if (response.status) {
                    if (typeof response.data.current_time !== 'undefined' && response.data.current_time) {
                        const currentTime = moment(moment(response.data.current_time).format('YYYY-MM-DD HH:mm:ss'))
                        const now = moment(moment().format('YYYY-MM-DD HH:mm:ss'))
                        const difference = Math.round(currentTime.diff(now, 'minutes') / 60) * 60
                        context.commit('setServerTimeDifference', difference, { root: true })
                    }

                    context.commit('setTimeZoneName', response.data.timezone_name, { root: true })

                    context.commit('setVersionCurrent', response.data.version_current, { root: true })
                    context.commit('setVersionMin', response.data.version_min, { root: true })
                    context.commit('setVersionRecommended', response.data.version_recommended, { root: true })
                    context.commit('setChatActive', response.data.chat, { root: true })

                    context.dispatch('loadData', response.data)
                    context.dispatch('loadAppIcon', { path: response.data.app_icon, lastUpdate: response.data.app_icon_last_update * 1000 }, { root: true })
                    context.dispatch('loadExternalTools', response.data.menu_items, { root: true })
                    //   context.commit('employee/setRoles',response.data.roles,{root: true})
                    var printer = Object.assign(response.data.printer)
                    // context.commit('printer/setPrinterData', printer, { root: true })
                    context.dispatch('printer/savePrinterData', printer, { root: true })

                    if (response.data.config && response.data.config.home) {
                        context.commit('setCustomHome', response.data.config.home, { root: true })
                    } else {
                        context.commit('setCustomHome', false, { root: true })
                    }

                    // free labelling
                    const freeLabelling = response.data.config && typeof response.data.config.freeLabelling !== 'undefined' && response.data.config.freeLabelling ? 1 : 0
                    context.commit('setFreeLabelling', freeLabelling, { root: true })

                    const pastLabelling = response.data.config && typeof response.data.config.pastLabelling !== 'undefined' && response.data.config.pastLabelling ? 1 : 0
                    context.commit('setPastLabelling', pastLabelling, { root: true })

                    const sensorLimitation = response.data.config && typeof response.data.config.sensorLimitation !== 'undefined' && response.data.config.sensorLimitation ? response.data.config.sensorLimitation : 0
                    context.commit('setSensorLimitation ', sensorLimitation, { root: true })

                    const relatedLabels = response.data.config && typeof response.data.config.relatedLabels !== 'undefined' && response.data.config.relatedLabels ? response.data.config.relatedLabels : 0
                    context.commit('setRelatedLabels', relatedLabels, { root: true })

                    const dateLimitation = response.data.config && typeof response.data.config.dateLimitation !== 'undefined' && response.data.config.dateLimitation ? response.data.config.dateLimitation : 0
                    context.commit('setDateLimitation', dateLimitation, { root: true })

                    const canCreateUsers = response.data.config && typeof response.data.config.canCreateUsers !== 'undefined' ? response.data.config.canCreateUsers : 1
                    context.commit('setCanCreateUsers', canCreateUsers, { root: true })

                    const maxLabelsPrint = response.data.config && typeof response.data.config.maxLabelsPrint !== 'undefined' && response.data.config.maxLabelsPrint ? response.data.config.maxLabelsPrint : 100
                    context.commit('setMaxLabelsPrint', maxLabelsPrint, { root: true })

                    const callLoginUsers = response.data.config && typeof response.data.config.callLoginUsers !== 'undefined' ? response.data.config.callLoginUsers : 0
                    context.commit('setCallLoginUsers', callLoginUsers, { root: true })

                    const timeout = response.data.config && typeof response.data.config.timeout !== 'undefined' && response.data.config.timeout ? parseInt(response.data.config.timeout) * 60 : idle_time
                    context.commit('setTimeout', timeout, { root: true })

                    const license_end_date = response.data && typeof response.data.license_end_date !== 'undefined' && response.data.license_end_date ? response.data.license_end_date : false

                    context.commit('setLicenseEndDate', license_end_date, { root: true })

                    const note_img = response.data.config && typeof response.data.config.note_img !== 'undefined' && response.data.config.note_img ? response.data.config.note_img : 'all'
                    context.commit('setNoteImg', note_img, { root: true })

                    const screenlockFlag = response.data.config && typeof response.data.config.screenlock !== 'undefined' ? response.data.config.screenlock : 0
                    context.commit('setScreenlockFlag', screenlockFlag, { root: true })

                    const offline = response.data.offline ? response.data.offline : 0
                    context.commit('setOffline', offline, { root: true })

                    context.commit(
                        'loginUser/setLocation',
                        {
                            id: response.data.id,
                            name: response.data.name,
                            account_name: response.data.account_name,
                            avatar: response.data.avatar,
                            identification: response.data.identification,
                            username: response.data.username,
                            timezone: response.data.timezone,
                            email: response.data.email,
                            token: response.data.token,
                            city: response.data.city,
                            state: response.data.state,
                            address: response.data.address,
                            cp: response.data.cp
                        },
                        { root: true }
                    )
                    context.commit('loginUser/setAccount', response.data.account_id, { root: true })

                    context.commit('conditionsVersion', response.data.conditions_version)
                    context.commit('login', response)

                    var loginUser = {}
                    loginUser.status = true
                    loginUser.emp_id = response.data.employee.id
                    loginUser.token = response.data.token
                    loginUser.user = response.data.employee
                    var avatar = loginUser.user.avatar
                    if (!avatar.includes('//')) {
                        loginUser.user.avatar = css_image_url + 'avatar/' + loginUser.user.avatar
                    }
                    loginUser.user.image = 'url(' + loginUser.user.avatar + ')'

                    context.commit('setToken', response.data.token)
                    context.dispatch('loadTimeZonesAndLanguages', {})

                    context.commit('setTrialEndDate', response.data.trial_end_date)
                    context.dispatch('calculateTrialDaysLeft', {})
                    context.commit('setTrialMessage', response.data.trial_message)

                    debug = 1
                    localStorage.setItem('debug', 1)

                    return context.dispatch('employee/loadEmployees', {}, { root: true }).then(function () {
                        return context.dispatch('employee/loadRoles', {}, { root: true }).then(function () {
                            context.commit('employee/addEmployee', loginUser.user, { root: true })
                            context.commit('loginUser/login', loginUser, { root: true })
                            return context.commit('setisLoaded', true)
                        })
                    })
                }
                return true
            })
            .catch((error) => {
                logError(error)
            })
    },
    async getScreenlockTasks(context) {
        context.commit('setScreenlockData', { checklist_id: false, message: '' })
        if (!fnCheckConnection()) {
            let screenlock = await db_screenlock.getItem('screenlock')
            if (screenlock) {
                screenlock = JSON.parse(screenlock)
                context.commit('setScreenlockData', screenlock.screenlock)

                if (screenlock.data) {
                    _.forEach(_.values(screenlock.data), async function (value, key) {
                        if (value.id !== 'undefined' && value !== 'undefined') {
                            await db_tasks.setItem(value.id, value)
                        }
                    })

                    return context.dispatch('tasks/mergeOffline', { data: screenlock.data }, { root: true }).then(function (response2) {
                        return context.dispatch('tasks/format', { data: response2, screenlock: true }, { root: true }).then(() => {
                            return context.dispatch('tasks/calcScore', false, { root: true })
                        })
                    })
                }
            }
            return
        } else {
            db_screenlock.clear().then(() => {
                db_screenlock.setItem('screenlock', JSON.stringify({ checklist_id: false, message: '' }))
            })
        }

        return api.get('checklist/screenlock').then(function (response) {
            context.commit('setScreenlockData', response.screenlock)

            db_screenlock.clear().then(() => {
                db_screenlock.setItem('screenlock', JSON.stringify(response))
            })

            if (response.data) {
                _.forEach(_.values(response.data), async function (value, key) {
                    if (value.id !== 'undefined' && value !== 'undefined') {
                        await db_tasks.setItem(value.id, value)
                    }
                })
            }
        })
    },
    getStoredSites(context, params) {
        let storedLocations = []
        return db_locations
            .iterate((value, key, iterationNumber) => {
                value.username = key
                storedLocations.push(value)
            })
            .then(() => {
                return storedLocations
            })
    },
    removeStoredSite(context, params) {
        return db_locations.removeItem(params.username).then(() => {
            return true
        })
    },

    // RECOVER PASSWORD PROCCESS
    sendForgotMail(context, params) {
        return api.post('location/password/recover', { identification: params.identification }).then(function (response) {
            if (response) {
                return response
            }
        })
    },
    sendCode(context, params) {
        return api.post('location/password/recover/validate/code', { location_id: params.location_id, code: params.code }).then(function (response) {
            if (response) {
                return response
            }
        })
    },
    resendCode(context, params) {
        return api.post('location/code/resend', { identification: params.identification }).then(function (response) {
            if (response) {
                return response
            }
        })
    },
    resetPassword(context, params) {
        return api.post('location/password/new', { location_id: params.location_id, password: params.password, hashPassword: params.hashPassword }).then(function (response) {
            if (response.status) {
                //   store.dispatch("checkChangesPrint", response.data.printer);
                context.dispatch('loadData', response.data)
                context.dispatch('loadAppIcon', { path: response.data.app_icon, lastUpdate: response.data.app_icon_last_update * 1000 }, { root: true })
                context.dispatch('loadExternalTools', response.data.menu_items, { root: true })
                log('PASO POR AQUI')
                var printer = Object.assign(response.data.printer)
                log('SETTING PRINTER', printer)
                // context.commit('printer/setPrinterData', printer, { root: true })
                context.dispatch('printer/savePrinterData', printer, { root: true })

                if (response.data.config && response.data.config.home) {
                    context.commit('setCustomHome', response.data.config.home, { root: true })
                } else {
                    context.commit('setCustomHome', false, { root: true })
                }

                // free labelling
                const freeLabelling = response.data.config && typeof response.data.config.freeLabelling !== 'undefined' && response.data.config.freeLabelling ? 1 : 0
                context.commit('setFreeLabelling', freeLabelling, { root: true })

                const pastLabelling = response.data.config && typeof response.data.config.pastLabelling !== 'undefined' && response.data.config.pastLabelling ? 1 : 0
                context.commit('setPastLabelling', pastLabelling, { root: true })

                const sensorLimitation = response.data.config && typeof response.data.config.sensorLimitation !== 'undefined' && response.data.config.sensorLimitation ? response.data.config.sensorLimitation : 0
                context.commit('setSensorLimitation ', sensorLimitation, { root: true })

                const relatedLabels = response.data.config && typeof response.data.config.relatedLabels !== 'undefined' && response.data.config.relatedLabels ? response.data.config.relatedLabels : 0
                context.commit('setRelatedLabels', relatedLabels, { root: true })

                const dateLimitation = response.data.config && typeof response.data.config.dateLimitation !== 'undefined' && response.data.config.dateLimitation ? response.data.config.dateLimitation : 0
                context.commit('setDateLimitation', dateLimitation, { root: true })

                const canCreateUsers = response.data.config && typeof response.data.config.canCreateUsers !== 'undefined' ? response.data.config.canCreateUsers : 1
                context.commit('setCanCreateUsers', canCreateUsers, { root: true })

                const maxLabelsPrint = response.data.config && typeof response.data.config.maxLabelsPrint !== 'undefined' && response.data.config.maxLabelsPrint ? response.data.config.maxLabelsPrint : 100
                context.commit('setMaxLabelsPrint', maxLabelsPrint, { root: true })

                const callLoginUsers = response.data.config && typeof response.data.config.callLoginUsers !== 'undefined' ? response.data.config.callLoginUsers : 0
                context.commit('setCallLoginUsers', callLoginUsers, { root: true })

                const timeout = response.data.config && typeof response.data.config.timeout !== 'undefined' && response.data.config.timeout ? parseInt(response.data.config.timeout) * 60 : idle_time
                context.commit('setTimeout', timeout, { root: true })

                const license_end_date = response.data && typeof response.data.license_end_date !== 'undefined' && response.data.license_end_date ? response.data.license_end_date : false

                context.commit('setLicenseEndDate', license_end_date, { root: true })

                const note_img = response.data.config && typeof response.data.config.note_img !== 'undefined' && response.data.config.note_img ? response.data.config.note_img : 'all'
                context.commit('setNoteImg', note_img, { root: true })

                const screenlockFlag = response.data.config && typeof response.data.config.screenlock !== 'undefined' ? response.data.config.screenlock : 0
                context.commit('setScreenlockFlag', screenlockFlag, { root: true })

                const offline = response.data.offline ? response.data.offline : 0
                context.commit('setOffline', offline, { root: true })

                context.commit(
                    'loginUser/setLocation',
                    {
                        account_name: response.data.account_name,
                        id: response.data.id,
                        name: response.data.name,
                        avatar: response.data.avatar,
                        identification: response.data.identification,
                        username: response.data.username,
                        timezone: response.data.timezone,
                        email: response.data.email,
                        token: response.data.token,
                        city: response.data.city,
                        state: response.data.state,
                        address: response.data.address,
                        cp: response.data.cp
                    },
                    { root: true }
                )
                context.commit('loginUser/setAccount', response.data.account_id, { root: true })
                context.commit('login', response)

                //   context.commit('employee/setRoles',response.data.roles,{root: true})
                if (context.rootGetters.getIsAndroid && FirebasePlugin) {
                    log('SETTING USER INFO Analytics & Crashlytics')
                    FirebasePlugin.setUserId(response.data.id)
                    FirebasePlugin.setUserProperty('account', response.data.account_id)
                    FirebasePlugin.setUserProperty('location_name', response.data.location_name)
                    FirebasePlugin.setCrashlyticsUserId(response.data.id)
                }
            } else {
                //   overlay.hide()
                return false
            }
        })
    },

    loadReleases(context, params) {
        const language = localStorage.getItem('language')
        let lang = ''
        switch (language) {
            case 'es':
                lang = 'es_ES'
                break
            case 'en':
                lang = 'en_US'
                break
            case 'pt':
                lang = 'pt_PT'
                break
            case 'de':
                lang = 'de_DE'
                break
        }
        return api
            .post('releases', { lang: lang })
            .then((response) => {
                context.commit('setAllReleases', response.data)
                return response
            })
            .catch((error) => {
                logError(error)
                return false
            })
    },

    async loadTimeZonesAndLanguages(context, params) {
        const languages = await db_languages.length()

        if (languages > 0) {
            return false
        }

        const response = await api.get('account/information')

        if (!response.status) {
            return false
        }

        for (let id in response.data.languages) {
            const language = response.data.languages[id]
            db_languages.setItem(id, language)
        }
    },

    async cleanOfflineDatabase() {
        await cleanLocalForage()
    },

    calculateTrialDaysLeft(context) {
        let trial_end_date = context.getters['getTrialEndDate']
        const serverTimeDifference = context.rootGetters['loginUser/getServerTimeDifference']
        const timezone = context.rootGetters['getTimeZoneName']
        let now = moment()

        if (trial_end_date === true) {
            context.commit('showTrialEndScreen', true)
        }

        if (typeof trial_end_date === 'string' && trial_end_date !== null) {
            try {
                const options = {
                    timeZone: timezone,
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                }
                const copy = moment(now)

                const dateTimeFormat = new Intl.DateTimeFormat([], options).formatToParts()
                const objectDate = {}
                for (const item of dateTimeFormat) {
                    objectDate[item.type] = item.value
                }
                const dateString = `${objectDate['day'].padStart(2, '0')}/${objectDate['month'].padStart(2, '0')}/${objectDate['year']} ${objectDate['hour'].padStart(2, '0')}:${objectDate['minute'].padStart(2, '0')}`
                now = moment(dateString, 'DD/MM/YYYY HH:mm')

                if (now.diff(copy, 'minutes') != serverTimeDifference) {
                    const difference = serverTimeDifference - now.diff(copy, 'minutes')
                    now = now.add(difference, 'minutes')
                }

                now = moment(now, 'DD/MM/YYYY')
            } catch (error) {
                logError(error)
            }

            trial_end_date = moment(trial_end_date)
            let differenceDays = trial_end_date.diff(now, 'days') + 1
            let trial_days_left = now.isAfter(trial_end_date) ? 0 : differenceDays

            context.commit('setTrialDaysLeft', trial_days_left)

            if (trial_end_date.isSameOrBefore(now)) {
                context.commit('showTrialEndScreen', true)
                router.push('home')
            }
        }
    },
    emailForgot(context, params) {
        return api.post('admin/employee/rememberpassword', params).then(function (response) {
            if (response.status) {
                return response
            } else {
                return response
            }
        })
    }
}

// mutations
const mutations = {
    login(state, response) {
        if (response.status) {
            state.hasLogin = true

            var dt = response.data

            const location = {
                id: dt.id,
                name: dt.name,
                avatar: dt.avatar,
                identification: dt.identification,
                username: dt.username,
                timezone: dt.timezone,
                email: dt.email,
                token: dt.token
            }

            state.location = location
            state.removeRolesLevel = dt.removeRolesLevel
            state.account = dt.account_id
            state.config = dt.config
            state.printer = dt.printer
            state.token = dt.token
            state.config = dt.config
            state.app_android = dt.app_android
            state.conditions_version = dt.conditions_version
            state.roles = dt.roles

            db_login.setItem('token', dt.token)
            db_login.setItem('location', location)
            db_login.setItem('removeRolesLevel', dt.removeRolesLevel)
            db_login.setItem('account', dt.account_id)
            db_login.setItem('config', dt.config)
            // db_login.setItem('printer', dt.printer)
            db_login.setItem('app_android', dt.app_android)
            db_login.setItem('app_icon', dt.app_icon)
            db_login.setItem('alex_info_url', dt.alex_info_url)
            db_login.setItem('roles', dt.roles)
            db_login.setItem('conditions_version', dt.conditions_version)
        } else {
            state.hasLogin = false
        }

        // this._vm.$overlay.hide()
    },
    setLogin(state) {
        // const token = await db_login.getItem('token')
        // if (typeof token !== 'undefined') {
        //     state.hasLogin = true
        // }
        log('setLogin', state.token)
        if (state.token != null && state.token != '') {
            state.hasLogin = true
        }
    },

    setIsLoadedLocation(state, loaded) {
        state.isLoadedLocation = loaded
        Vue.set(state, 'isLoadedLocation', loaded)
    },
    setAuditorLocalSelected(state, auditor) {
        state.auditorLocalSelected = auditor
        Vue.set(state, 'auditorLocalSelected', auditor)
    },
    logout(state) {
        log('DO LOGOUT')
        var language = localStorage.language
        localStorage.clear()
        // db_login.clear()
        localStorage.setItem('language', language)

        // reset LOCAL
        state.hasLogin = false

        // this._vm.$overlay.hide()
    },
    conditionsVersion(state, version) {
        state.conditionsVersion = version
        db_login.setItem('conditions_version', version)
    },

    setisLoaded(state, loaded) {
        state.loadLocalForage = loaded
    },

    setReleases(state, data) {
        state.releases = data
        Vue.set(state, 'releases', data)
    },

    setAllReleases(state, data) {
        state.allReleases = data
        Vue.set(state, 'allReleases', data)
    },

    setBlacklistData(state, data) {
        const blackListData = {
            warning: data.warning,
            blocked: data.blocked
        }
        state.blackListData = blackListData
        Vue.set(state, 'blacklistData', blackListData)
    },
    setScreenlockData(state, data) {
        const screenlockData = {
            checklist_id: data.checklist_id,
            message: data.message
        }

        Vue.set(state, 'screenlock', screenlockData)
    },
    setWrongIp(state, data) {
        state.wrong_ip = data
        Vue.set(state, 'wrong_ip', data)
    },
    setToken(state, data) {
        state.token = data
        Vue.set(state, 'token', data)
        return db_login.setItem('token', data)
    },
    setTrialEndDate(state, data) {
        state.trial_end_date = data
        db_login.setItem('trial_end_date', data)
        Vue.set(state, 'trial_end_date', data)
    },
    showTrialEndScreen(state, data) {
        state.showTrialEndScreen = data
        Vue.set(state, 'showTrialEndScreen', data)
    },
    setTrialDaysLeft(state, data) {
        state.trial_days_left = data
        Vue.set(state, 'trial_days_left', data)
    },
    setTrialMessage(state, data) {
        state.trial_message = data
        Vue.set(state, 'trial_message', data)
    },
    setConfig(state, config) {
        state.config = config
        Vue.set(state, 'config', config)
        db_login.setItem('config', config)
    },
    setKitchenModeFeatureFlag(state, value) {
        state.kitchenModeFeatureFlag = value
    }
}

async function initLocalForage() {
    log('init local forage')

    await localStorageToLocalForage()

    state.location = await db_login.getItem('location')
    state.removeRolesLevel = await db_login.getItem('removeRolesLevel')
    state.conditionsVersion = await db_login.getItem('conditions_version')
    state.token = await db_login.getItem('token')
    state.roles = await db_login.getItem('roles')
    state.app_android = await db_login.getItem('app_android')
    state.account = await db_login.getItem('account')
    state.printer = await db_login.getItem('printer')
    state.config = await db_login.getItem('config')
    state.loadLocalForage = true
    state.trial_end_date = await db_login.getItem('trial_end_date')
    state.auditor = await db_login.getItem('auditor')
}

async function localStorageToLocalForage() {
    const auditor = localStorage.auditor
    await db_login.setItem('auditor', auditor)

    const token = localStorage.token
    if (typeof token !== 'undefined') {
        await db_login.setItem('token', token)
        localStorage.clear()
    }
}

function cleanLocalForage() {
    console.warn('cleanLocalForage')
    let promises = [db_issues.clear(), db_issues_templates.clear(), db_tasks.clear(), db_templates.clear(), db_timers.clear(), db_checks_to_sync.clear(), db_login.clear(), db_updates.clear(), db_employees.clear(), db_roles.clear(), db_categories.clear(), db_products.clear(), db_collections.clear(), db_printlogs.clear(), db_error_logs.clear(), db_library_categories.clear(), db_sections.clear(), db_files.clear(), db_highlight_files.clear(), db_logbook.clear(), db_legal_conditions.clear(), dbEmployeeLoginLogs.clear(), db_printlogs_android.clear(), db_languages.clear(), db_tasks_queue.clear(), registers_items.clear(), db_registers_queue.clear(), audits_items.clear(), db_audits_queue.clear(), issues_items.clear(), db_issues_queue.clear(), db_registers.clear(), db_screenlock.clear()]

    return Promise.all(promises)
        .then((response) => {
            return true
        })
        .catch((error) => {
            logError(error)
            return false
        })
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
